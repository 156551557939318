import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import takeCashOut from "../images/Landing Page/Take Cash Out.svg";
import consolidateDebt from "../images/Landing Page/Consolidate Debt.svg";
import lowerYourPayment from "../images/Landing Page/Lower Your Payment.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="relative mb-16 overflow-hidden py-20 md:mb-24 md:py-56">
        <div className="absolute left-0 top-0 h-full w-full">
          <StaticImage
            src="../images/Landing Page/Hero.jpg"
            loading="eager"
            className="h-full"
            imgClassName="object-left"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[530px]">
            <div className="mb-3.5 text-lg font-extrabold uppercase text-secondary-500">
              Trusted since 1989
            </div>
            <div className="mb-4 font-heading text-mobile-7xl font-extrabold text-white md:text-8xl">
              Goal: Refresh
            </div>
            <p className="mb-0 text-xl text-white md:mb-3 md:text-4xl">
              Our dedicated refinance mortgage experts are ready to help
            </p>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Put your equity to work.</h2>
          </header>
          <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div className="rounded-3xl bg-secondary-500/60 px-6 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={takeCashOut}
                  alt="Take Cash Out"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Take Cash Out</h3>
                <p className="mb-0 text-typography-heading">
                  Get a lump sum to tackle big projects, invest in property, or
                  pay for expenses.
                </p>
              </div>

              <div className="mb-6 mt-8 flex items-center space-x-3">
                <div className="h-px w-full bg-typography-heading/30"></div>
                <span className="whitespace-nowrap text-sm font-bold text-typography-heading">
                  Good For
                </span>
                <div className="h-px w-full bg-typography-heading/30"></div>
              </div>

              <ul className="styled-list-checkmark alt mb-7">
                <li>Renovating your home</li>
                <li>Investing in another property</li>
                <li>Paying for major expenses</li>
              </ul>

              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="w-full !text-lg"
              />
            </div>

            <div className="rounded-3xl bg-secondary-500/60 px-6 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={consolidateDebt}
                  alt="Consolidate Debt"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Consolidate Debt</h3>
                <p className="mb-0 text-typography-heading">
                  Stop paying so much in interest. Start making just one single,
                  low-interest payment.
                </p>
              </div>

              <div className="mb-6 mt-8 flex items-center space-x-3">
                <div className="h-px w-full bg-typography-heading/30"></div>
                <span className="whitespace-nowrap text-sm font-bold text-typography-heading">
                  Good For
                </span>
                <div className="h-px w-full bg-typography-heading/30"></div>
              </div>

              <ul className="styled-list-checkmark alt mb-7">
                <li>Eliminating high-interest debt</li>
                <li>Improving your credit score</li>
                <li>Rolling your debt into one loan</li>
              </ul>

              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="w-full !text-lg"
              />
            </div>

            <div className="rounded-3xl bg-secondary-500/60 px-6 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={lowerYourPayment}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Lower Your Payment</h3>
                <p className="mb-0 text-typography-heading">
                  Make your biggest monthly expense easier to manage and put
                  extra money in your pocket.
                </p>
              </div>

              <div className="mb-6 mt-8 flex items-center space-x-3">
                <div className="h-px w-full bg-typography-heading/30"></div>
                <span className="whitespace-nowrap text-sm font-bold text-typography-heading">
                  Good For
                </span>
                <div className="h-px w-full bg-typography-heading/30"></div>
              </div>

              <ul className="styled-list-checkmark alt mb-7">
                <li>Gaining extra cash flow</li>
                <li>Applying the savings to debt</li>
                <li>Becoming mortgage-free faster!</li>
              </ul>

              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="w-full !text-lg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Your Mortgage Rate Options.</h2>
          </header>
          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:gap-x-12">
            <div className="rounded-lg bg-white p-6 shadow-4xl">
              <h3 className="heading-four">Fixed Rate</h3>
              <p className="mb-0">
                A fixed-rate mortgage carries a set interest rate for the loan's
                lifetime, and market fluctuations will not impact the rate. As a
                result, your monthly mortgage payment will always remain the
                same, making budgeting easy.
              </p>
            </div>

            <div className="rounded-lg bg-white p-6 shadow-4xl">
              <h3 className="heading-four">Adjustable ARM</h3>
              <p className="mb-0">
                There is an initial loan period with an adjustable-rate mortgage
                where you pay a fixed rate. Then the interest rate changes
                periodically throughout the remainder of the loan. The interest
                rate change depends on how the market has fluctuated.
              </p>
            </div>

            <div className="rounded-lg bg-white p-6 shadow-4xl">
              <h3 className="heading-four">Interest Only</h3>
              <p className="mb-0">
                When you have an interest-only mortgage, you pay just the
                interest for the first several years of the loan. Then the
                principal is amortized into the payment schedule after that
                initial period. At the end of the interest-only period, you also
                have the option to make a balloon payment or refinance and get a
                new loan.
              </p>
            </div>

            <div className="rounded-lg bg-white p-6 shadow-4xl">
              <h3 className="heading-four">Graduated Payments</h3>
              <p className="mb-0">
                The monthly payments on a graduated payment mortgage start at a
                set amount and gradually increase over time. This allows
                prospective homeowners to start with a lower monthly mortgage
                payment and can also be an option for those who might not
                otherwise qualify for a loan.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-6 md:mb-6">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Estimate your payment.</h2>
          </header>

          <iframe
            src="https://mortgage-tools.flywheelsites.com/home-mortgage-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1100px] md:h-[700px]"
          ></iframe>
        </div>
      </section>

      <Testimonials />
      <ValueProps />

      <div className="container">
        <section className="mb-20 rounded-3xl bg-primary-900 px-4 py-20 md:mb-32">
          <div className="mx-auto max-w-[688px] text-center">
            <h2 className="mb-4 text-white">
              Get in Touch With Our Team Today!
            </h2>
            <p className="text-primary-50">
              We’re happy to connect with you and answer any questions you might
              have. Reach our today!
            </p>
            <div className="flex flex-wrap items-center justify-center space-y-4 md:flex-nowrap md:space-x-4 md:space-y-0">
              <ButtonSolid
                href="/home-purchase/"
                text="Apply Now!"
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
              <ButtonSolid
                href="tel:866-299-1600"
                text="(866) 299-1600"
                altStyle={2}
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
